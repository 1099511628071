import React from 'react'
import App from 'next/app'
import '../css/tailwind.css';
// import { init } from '../utils/Sentry';

import { config, library } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

import { faMapPin, faBuilding, faSearch, faSort, faAngleRight, faAngleLeft, faStar as fasStar, faEnvelope, faHeart as fasHeart, faBolt, faRulerCombined, faTimes, faPhoneAlt, faDownload, faSearchPlus, faSearchMinus, faInfoCircle, faChevronRight, faChevronLeft, faBed, faTable, faThList, faMinusSquare, faArrowLeft, faEye, faEyeSlash, faCaretDown, faMapMarkerAlt, faCheckCircle, faCheck, faSlidersH, faTimesCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar, faHeart as farHeart, faBookmark, faBuilding as farBuilding, faEnvelope as farEnvelope, faFileImage, faFilePdf, faStarHalf, faImages, faClock, faCheckSquare, faImage, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp, faFacebook, faTwitter, faPinterest, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Router } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { TRACKING_VARIABLES } from '../utils/ConstData';

library.add(faMapPin, faBuilding, faBolt, faSearch, faSort, faAngleRight, fasStar, farStar, farEnvelope, faEnvelope, faWhatsapp, faFacebook, faTwitter, faPinterest, faInstagram, fasHeart, farHeart, faRulerCombined, faTimes, faPhoneAlt, faAngleLeft, faDownload, faSearchPlus, faSearchMinus, faInfoCircle, faChevronRight, faChevronLeft, faBed, faBookmark, farBuilding, faFileImage, faFilePdf, faTable, faThList, faStarHalf, faMinusSquare, faArrowLeft, faEye, faEyeSlash, faCaretDown, faMapMarkerAlt, faImages, faCheckCircle, faCheck, faClock, faSlidersH, faImage, faCalendarAlt, faTimesCircle, faCheckSquare, faPlus)

// if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
//   init()
// }

import('react-toastify')
  .then(module => ({ toast: module.toast, Slide: module.Slide }))
  .then(response => {
    const { toast, Slide } = response
    const contextClass = {
      success: "bg-blue-D8E9F0",
      error: "bg-red-7b",
      info: "bg-gray-600",
      warning: "bg-orange-400",
      default: "bg-indigo-600",
      dark: "bg-white-600 font-gray-300",
    }

    toast.configure({
      autoClose: 2000,
      pauseOnFocusLoss: true,
      newestOnTop: true,
      draggable: false,
      limit: 1,
      className: 'w-full md:w-auto lg:-mt-4 p-0',
      toastClassName: (context) => contextClass[context?.type || 'default'] + ` ${context?.type === 'error' ? 'text-white' : 'text-black-48'} px-5 min-h-nav-mobile flex flex-row items-center min-w-full md:min-w-375px`,
      bodyClassName: 'pr-2',
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: false,
      transition: Slide,
    })
  })

class MyApp extends App {
  componentDidUpdate() {
    this.getUtms(this.props.router.query)
  }

  componentDidMount() {
    this.handleToast()

    Router.events.on('routeChangeComplete', this.routeChangeComplete)
    Router.events.on('routeChangeComplete', this.handleToast)

    if (process.browser) {
      const url = window.location.href
      this.handleRouteChange(url)
    }

    this.getUtms(this.props.router.query)
  }

  getUtms(query: ParsedUrlQuery) {
    if ((query && Object.keys(query).length > 0) || document.referrer) {

      const queryToCookie: any = {};
      let addToCookie = false;

      var dataReferrer = ''

      if (!document.referrer.includes(`${process.env.NEXT_PUBLIC_MAINSITE_URL}`)) {
        dataReferrer = document.referrer
      }

      TRACKING_VARIABLES.map((data) => {
        const checkData = data === 'referrer' ? dataReferrer : query[data];

        if (checkData) {
          queryToCookie[data] = checkData;
          addToCookie = true;
        }
      })

      if (addToCookie) {
        import('../utils/Cookie')
          .then(module => module._setCookie)
          .then(_setCookie => {
            for (const [key, value] of Object.entries(queryToCookie)) {
              _setCookie(key, value as string)
            }
          })
          .catch(error => console.error(error))
      }
    }
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.routeChangeComplete)
    Router.events.off('routeChangeComplete', this.handleToast)
  }

  routeChangeComplete(url: string) {
    try {
      if (!url.includes('signin') && !url.includes('signup') && !url.includes('auth')) {
        localStorage.setItem('source_url', url)
      }
    } catch {
      // console.error('Cookies disabled')
    }
  }

  handleToast() {
    try {
      const stringToastData = sessionStorage.getItem('toast')
      const toastData = stringToastData ? JSON.parse(stringToastData) : null

      if (toastData) {
        import('react-toastify')
          .then(module => module.toast)
          .then(toast => {
            toast(toastData.message, toastData.options || {})
            sessionStorage.removeItem('toast')
          })
      }
    } catch {
      import('react-toastify')
        .then(module => module.toast)
        .then(toast => {
          toast("This website won't work propertly without Cookies enabled", {
            type: 'error',
            autoClose: false,
          })
        })
      // console.error('Cookies disabled')
    }
  }

  handleRouteChange(url: string) {
    try {
      if (!url.includes('signin') && !url.includes('signup') && !url.includes('auth')) {
        localStorage.setItem('source_url', url)
      }
    } catch {
      // console.error('Cookies disabled')
    }
  }

  render() {
    const { Component, pageProps } = this.props;

    return <Component {...pageProps} />
  }
}

export default MyApp