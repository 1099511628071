export const TRACKING_VARIABLES = [
    'ref',
    'utm_id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'AdSetName',
    'adgroupid',
    'creative',
    'keyword',
    'gclid',
    'fbclid',
    '_fbp',
    '_fbc',
    'referrer'
  ];